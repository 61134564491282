import React from 'react';
import {
  InputGroup,
  InputRightElement,
  IconButton,
  VStack,
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setCookie } from 'nookies';
import { useRouter } from 'next/router';
import { useLogin } from 'hooks/auth/useLogin';
import { useLogEvent } from 'hooks/firebase';
import yup from 'utils/formValidators';
import { useRefreshToken } from 'hooks';
import { UserSessionModel } from 'types/authentication';
import JstVisiblePassword from 'components/icons/Onboarding/JstVisiblePassword';
import JstHiddenPassword from 'components/icons/Onboarding/JstHiddenPassword';
import { useScreenClass } from 'react-grid-system';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth } from 'store/auth/index';

const formSchema = yup.object().shape({
  login: yup
    .string()
    .email('Digite um e-mail válido!')
    .required('Digite um e-mail!'),
  password: yup
    .string()
    .min(3, 'A senha deve conter no mínimo 3 caracteres!')
    .required('Digite uma senha!'),
  reCaptcha: yup.string().required('Por favor, selecione o campo acima'),
});

type FormProps = {
  login: string;
  password: string;
  reCaptcha: string;
};

const LoginForm = () => {
  const CAPTCHA_KEY = process.env.NEXT_PUBLIC_CAPTCHA_KEY;

  const reCaptchaRef = React.useRef<ReCAPTCHA>() as any;
  const screenClass = useScreenClass();
  const isMobile = !['xxl', 'xl', 'lg'].includes(screenClass);
  const router = useRouter();
  const mutation = useLogin(reCaptchaRef);
  const { refetch } = useRefreshToken(false);
  const [showPass, setShowPass] = React.useState(false);
  const { setAuth } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(formSchema),
  });
  const registerEvent = useLogEvent();

  const handleShowPass = React.useCallback(() => {
    setShowPass(!showPass);
    registerEvent('show_hide_login_pass');
  }, [registerEvent, showPass]);

  const handleForgotPassword = React.useCallback(() => {
    router.push('/recuperar-senha');
    registerEvent('forgot_password');
  }, [registerEvent, router]);

  const handleCreateAccount = () => {
    router.push('/onboarding');
  };

  /**
   * Executa a verificação do captcha.
   */
  const handleCaptchaValue = (value: string) => {
    if (!value) {
      return;
    }
    setValue(`reCaptcha`, value);
    clearErrors('reCaptcha');
  };

  /**
   * Gerencia o armazenamento do token de autenticação.
   */
  const handleTokenManagement = React.useCallback(
    (data: UserSessionModel) => {
      if (data?.authorization) {
        const token = data?.authorization;
        const maxAge = 86400000;
        setCookie(null, 'authToken', token, {
          maxAge,
          path: '/',
        });
        refetch();
      }
    },
    [refetch],
  );

  /**
   * Login do usuário.
   */
  const handleSignIn = async (data: FormProps) => {
    resetField('reCaptcha');
    const mutationResult = await mutation.mutateAsync(data);
    if ('authorization' in mutationResult) {
      setAuth(true);
      handleTokenManagement(mutationResult);
      registerEvent('login', { method: 'email_web' });
    } else {
      registerEvent('login', { method: 'onboarding_web' });
    }
  };

  return (
    <Flex
      flexDir="column"
      mt={10}
      justifyContent="center"
      fontFamily="Montserrat"
    >
      <Box as="form" onSubmit={handleSubmit(handleSignIn)} width="100%">
        <FormControl isInvalid={errors?.login?.message !== undefined}>
          <FormLabel
            color="#696F79"
            fontWeight="bold"
            mb="3.5"
            lineHeight="20px"
          >
            E-mail
          </FormLabel>
          <Input
            variant="onboarding"
            size="lg"
            id="input-login"
            type="email"
            placeholder="Digite seu e-mail"
            autoComplete="e-mail"
            autoFocus={true}
            data-testid="input-login"
            aria-label="Seu e-mail da Conta Justa Web"
            isInvalid={!!(errors.login && errors.login.message)}
            {...register('login')}
            color="#494949"
          />
          <FormErrorMessage fontFamily="Inter">
            {errors?.login?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={6} isInvalid={errors?.password?.message !== undefined}>
          <FormLabel
            color="#696F79"
            fontWeight="bold"
            mb="3.5"
            lineHeight="20px"
          >
            Senha
          </FormLabel>
          <InputGroup size="lg">
            <Input
              variant="onboarding"
              placeholder="Digite sua senha"
              type={showPass ? 'text' : 'password'}
              autoComplete="current-password"
              id="input-password"
              data-testid="input-password"
              size="lg"
              aria-label="Sua senha da Conta Justa Web"
              isInvalid={!!(errors.password && errors.password.message)}
              {...register('password')}
              color={showPass ? '#494949' : '#043C68'}
            />
            <InputRightElement m={{ base: 0, sm: 2 }}>
              <IconButton
                variant="ghost"
                onClick={handleShowPass}
                aria-label="Exibir/Ocultar Senha"
                borderRadius="full"
                icon={
                  showPass ? (
                    <JstVisiblePassword color="#043C68" />
                  ) : (
                    <JstHiddenPassword color="#043C68" />
                  )
                }
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage fontFamily="Inter">
            {errors?.password?.message}
          </FormErrorMessage>
        </FormControl>
        <Box mt="28px" data-testid="oi">
          <FormControl
            display="grid"
            justifyContent="center !important"
            isInvalid={errors?.reCaptcha?.message !== undefined}
          >
            <ReCAPTCHA
              sitekey={CAPTCHA_KEY as string}
              onChange={value => handleCaptchaValue(value as string)}
              ref={reCaptchaRef as any}
              hl="pt-BR"
            />
            <FormErrorMessage fontFamily="Inter">
              {errors?.reCaptcha?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <VStack spacing={8} mt={10}>
          <Button
            height="48px"
            background="#183B65"
            borderRadius="24px"
            padding="12px 24px"
            color="#FFFFFF"
            fontWeight="600"
            fontSize="16px"
            lineHeight="150%"
            width="100%"
            colorScheme="brand"
            size="lg"
            type="submit"
            isLoading={mutation?.isLoading}
            loadingText="Entrando..."
            aria-label="Login na Conta Justa Web"
            isDisabled={mutation?.isLoading}
            data-testid="submit"
          >
            Entrar
          </Button>
          <Button
            type="button"
            variant="link"
            onClick={handleForgotPassword}
            aria-label="Esqueceu sua senha do Conta Justa? Clique aqui."
            fontWeight="600"
            textDecoration="underline"
            color="#076395"
            fontSize={isMobile ? '16px' : '20px'}
            lineHeight="24px"
          >
            Esqueceu sua senha?
          </Button>
        </VStack>
        <Box
          width={isMobile ? '81%' : '58%'}
          justifyContent="center"
          alignContent="center"
          margin="auto"
          mt="4.5rem"
          bottom="15px"
          borderBottom="0.7px solid "
          color="#7D7D7D"
        ></Box>
        <Flex w="100%" mt={9}>
          <Button
            type="button"
            variant="link"
            onClick={handleCreateAccount}
            aria-label="Não tem uma conta? Clique aqui."
            fontWeight="600"
            fontSize="20px"
            color="#213A62"
            lineHeight="30px"
            m="auto"
          >
            Criar Conta
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default LoginForm;
