import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useSetUserID } from 'hooks/firebase';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from 'react-query';
import { userLogin } from 'services/auth';
import { AuthenticationPayload } from 'types/authentication';

/**
 * Hook para o login do usuário.
 */
export const useLogin = (reCaptchaRef: React.MutableRefObject<ReCAPTCHA>) => {
  const firebaseUser = useSetUserID();
  const router = useRouter();
  const toast = useToast();
  const accountAdminNotAcceptedTerms =
    'Você ainda não pode acessar a Conta Justa pois o administrador da conta ainda não aceitou nossos termos de uso. Por favor, fale com seu administrador para acessar a Conta Justa e aceitar os termos!';
  return useMutation(
    'auth/login',
    ({ login, password, reCaptcha }: AuthenticationPayload) =>
      userLogin({ login, password, reCaptcha }),
    {
      onError: (error: AxiosError) => {
        reCaptchaRef.current.reset();
        if (error?.response?.status === 401) {
          if (
            error?.response?.data?.errors?.[0] === accountAdminNotAcceptedTerms
          ) {
            toast({
              title: 'Ops!',
              description: accountAdminNotAcceptedTerms,
              status: 'error',
              position: 'bottom-left',
            });
            return;
          } else {
            toast({
              title: 'Credenciais Inválidas',
              description: 'Email ou senha incorretos. Tente novamente!',
              status: 'error',
              position: 'bottom-left',
            });
            return;
          }
        }

        toast({
          title: 'Ops!',
          description:
            'Ocorreu um erro ao tentar fazer login. Tente novamente!',
          status: 'error',
          position: 'bottom-left',
        });
      },
      onSuccess: data => {
        /**
         * A presença do currentStep indica que o cadastro do auto credenciamento
         * ainda não foi finalizado.
         */
        if ('currentStep' in data) {
          const id = data.id;
          router.prefetch(`/onboarding/finalizar?id=${id}&referer=login`);
          destroyCookie(null, 'authToken', { path: '/' });
          router.push(`/onboarding/finalizar?id=${id}&referer=login`);
          return;
        }

        router.prefetch('/painel');

        if (!data?.seller?.termsOfContract) {
          router.push('/termos');
          return;
        }

        if (data?.establishments[0]?.id) {
          firebaseUser(data?.establishments[0]?.id);
        }

        router.push('/painel');
      },
    },
  );
};
